import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="prose prose-a:break-all">
      <ol role="list">
        <li>
          <h5>Postanowienia Ogólne</h5>
          <ol role="list">
            <li>
              Administratorem Danych jest: "WIMIKO" s.c. Wiesław Korzonek,
              Michał Korzonek, ul. Częstochowska 40, 32-085 Modlnica NIP:
              513-025-98-45 e-mail: wimiko@onet.eu
            </li>
            <li>
              Ochrona danych odbywa się zgodnie z zachowaniem powszechnie
              obowiązujących przepisów prawa, a ich przechowywanie odbywa się na
              zabezpieczonych serwerach.
            </li>
            <li>
              Na potrzeby lepszego odbioru Polityki Prywatności termin
              „Użytkownik” zastąpiony został określeniem „Ty”, „Administrator”
              określeniem – „My”.
            </li>
            <li>
              Termin „RODO” oznacza Rozporządzenie Parlamentu Europejskiego i
              Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony
              osób fizycznych w związku z przetwarzaniem danych osobowych i w
              sprawie swobodnego przepływu takich danych oraz uchylenia
              dyrektywy 95/46/WE.
            </li>
            <li>
              Dane osobowe podawane w formularzach są traktowane jako poufne i
              pozostają niewidoczne dla osób nieuprawnionych.
            </li>
          </ol>
        </li>
        <li>
          <h5>Administrator Danych</h5>
          <ol role="list">
            <li>
              Usługodawca jest Administratorem Danych swoich klientów.
              Przetwarzamy Twoje dane: imię, nazwisko, adres e-mail, numer
              telefonu, dane adresowe.
            </li>
            <li>
              Dane osobowe przetwarzane są:
              <ol role="list">
                <li>
                  z zachowaniem przepisów dotyczących ochrony danych osobowych;
                </li>
                <li>zgodnie z wdrożoną Polityką Prywatności;</li>
                <li>
                  w zakresie i celu niezbędnym do nawiązania, ukształtowania
                  treści Umowy, zmiany bądź jej rozwiązania oraz prawidłowej
                  realizacji Usług, sprzedaży Produktów, świadczonych drogą
                  elektroniczną;
                </li>
                <li>
                  w zakresie i celu niezbędnym do wypełnienia uzasadnionych
                  interesów (prawnie usprawiedliwionych celów), a przetwarzanie
                  nie narusza praw i wolności osoby, której dane dotyczą:
                  <ul role="list">
                    <li>
                      w zakresie i celu zgodnym z wyrażoną przez Ciebie zgodą
                      jeżeli wysłałeś wiadomość przez formularz kontaktowy;
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              Każda osoba, której dane dotyczą (jeżeli jesteśmy ich
              Administratorem Danych) ma prawo do dostępu do danych,
              sprostowania, usunięcia lub ograniczenia przetwarzania, prawo
              sprzeciwu, prawo wniesienia skargi do organu nadzorczego.
            </li>
            <li>
              Kontakt z osobą nadzorującą przetwarzanie danych osobowych w
              organizacji Usługodawcy jest możliwy drogą elektroniczną pod
              adresem e-mail podanym w pkt 1.1 niniejszej Polityki Prywatności.
            </li>
            <li>
              Zastrzegamy sobie prawo do przetwarzania Twoich danych po
              rozwiązaniu Umowy lub cofnięciu zgody tylko w zakresie na potrzeby
              dochodzenia ewentualnych roszczeń przed sądem lub jeżeli przepisy
              krajowe albo unijne bądź prawa międzynarodowego obligują nas do
              retencji danych.
            </li>
            <li>
              Usługodawca ma prawo udostępniać dane osobowe Użytkownika oraz
              innych jego danych podmiotom upoważnionym na podstawie właściwych
              przepisów prawa (np. organom ścigania).
            </li>
            <li>
              Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody
              bądź wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie
              danych osobowych.
            </li>
            <li>
              Usługodawca nie udostępnia danych osobowych innym podmiotom
              aniżeli upoważnionym na podstawie właściwych przepisów prawa.
            </li>
            <li>
              Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas
              albo przetwarzający, z którymi ściśle współpracujemy i którzy, tak
              samo jak My, stosują się do przepisów oraz wymogów ochrony danych
              osobowych.
            </li>
          </ol>
        </li>
        <li>
          <h5>Pliki Cookies</h5>
          <ol role="list">
            <li>
              Witryna używa Plików Cookies. Są to niewielkie pliki tekstowe
              wysyłane przez serwer www i przechowywane przez oprogramowanie
              komputera przeglądarki. Kiedy przeglądarka ponownie połączy się ze
              stroną, witryna rozpoznaje rodzaj urządzenia, z którego łączy się
              użytkownik. Parametry pozwalają na odczytanie informacji w nich
              zawartych jedynie serwerowi, który je utworzył. Cookies ułatwiają
              więc korzystanie z wcześniej odwiedzonych witryn.
            </li>
            <li>
              Gromadzone informacje dotyczą adresu IP, typu wykorzystywanej
              przeglądarki, języka, rodzaju systemu operacyjnego, dostawcy usług
              internetowych, informacji o czasie i dacie, lokalizacji oraz
              informacji przesyłanych do witryny za pośrednictwem formularza
              kontaktowego.
            </li>
            <li>
              Zebrane dane wykorzystywane są do monitorowania i sprawdzenia, w
              jaki sposób użytkownicy korzystają z naszych stron www, aby
              usprawniać funkcjonowanie serwisu zapewniając bardziej efektywną i
              bezproblemową nawigację. Monitorowania informacji o użytkownikach
              dokonujemy korzystając z narzędzia Google Analytics, które
              rejestruje zachowanie użytkowników w witrynie. Cookies
              identyfikuje użytkownika, dzięki czemu dopasowywane są treści
              witryny, z której korzysta, do potrzeb użytkownika. Dzięki
              zapamiętaniu jego preferencji, umożliwione są odpowiednie
              dopasowania skierowanych do niego reklam i przekazów
              marketingowych. Stosujemy Pliki Cookies, aby zagwarantować wysoki
              poziom wygody naszego serwisu, a zebrane dane są wykorzystywane
              jedynie wewnątrz firmy w celu optymalizacji działań.
            </li>
            <li>
              Na naszej witrynie wykorzystywane są następujące typy Plików
              Cookies:
              <ol role="list">
                <li>
                  „niezbędne” Pliki Cookies, umożliwiające korzystanie z usług
                  dostępnych w ramach serwisu, np. uwierzytelniające Pliki
                  Cookies wykorzystywane do usług wymagających uwierzytelniania
                  w ramach serwisu;
                </li>
                <li>
                  Pliki Cookies służące do zapewnienia bezpieczeństwa, np.
                  wykorzystywane do wykrywania nadużyć w zakresie
                  uwierzytelniania w ramach serwisu;
                </li>
                <li>
                  „wydajnościowe” Pliki Cookies, umożliwiające zbieranie
                  informacji o sposobie korzystania ze stron internetowych
                  serwisu;
                </li>
                <li>
                  „funkcjonalne” Pliki Cookies, umożliwiające „zapamiętanie”
                  wybranych przez użytkownika ustawień i personalizację
                  interfejsu użytkownika, np. w zakresie wybranego języka lub
                  regionu, z którego pochodzi użytkownik, rozmiaru czcionki,
                  wyglądu strony internetowej itp.;
                </li>
                <li>
                  „reklamowe” Pliki Cookies, umożliwiające dostarczanie
                  użytkownikom treści reklamowych bardziej dostosowanych do ich
                  zainteresowań (remarketing w wyszukiwarkach, w sieci
                  reklamowej Google (GDN), remarketing z wykorzystanie,
                  technologii pixel kodów na Facebook’u, inne sieci reklamowe).
                </li>
              </ol>
            </li>
            <li>
              5. Użytkownik w każdej chwili ma możliwość wyłączenia lub
              przywrócenia opcji gromadzenia Plików Cookies poprzez zmianę
              ustawień w przeglądarce internetowej. Instrukcja zarządzania
              Plikami Cookies jest dostępna na stronach:
              <ol role="list">
                <li>
                  <a href="http://www.allaboutcookies.org/manage-cookies/">
                    http://www.allaboutcookies.org/manage-cookies/
                  </a>
                </li>
                <li>
                  <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pl">
                    https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pl
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/login.php?next=https%3A%2F%2Fpl-pl.facebook.com%2Fads%2Fpreferences">
                    https://www.facebook.com/login.php?next=https%3A%2F%2Fpl-pl.facebook.com%2Fads%2Fpreferences
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2Fads%2Fpreferences%2F%3Fentry_product%3Dad_settings_screen">
                    https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2Fads%2Fpreferences%2F%3Fentry_product%3Dad_settings_screen
                  </a>
                </li>
              </ol>
            </li>
            <li>
              Więcej o Plikach Cookies możesz przeczytać tutaj:{' '}
              <a href="https://pl.wikipedia.org/wiki/HTTP_cookie">
                https://pl.wikipedia.org/wiki/HTTP_cookie
              </a>
            </li>
          </ol>
        </li>
        <li>
          <h5>Wyłączenie odpowiedzialności</h5>
          <ol role="list">
            <li>
              Administrator strony nie bierze na siebie odpowiedzialności za
              zamieszczone reklamy. Kupujący powinien być ostrożny odpowiadając
              na reklamę, bądź wysyłając pieniądze. Wprawdzie przykładamy sporą
              wagę do tego, aby reklamodawcy, którzy publikują tu swoje reklamy
              byli wiarygodni, ale nie możemy odpowiadać za ich czyny. Dane
              adresowe jak i szczegóły oferty każdego reklamodawcy można uznać
              za pewne tylko w momencie publikacji.
            </li>
            <li>
              Wydawca nie ponosi odpowiedzialności za szkody powstałe podczas
              korzystania z serwisu, ani następstwa z tego wynikłe. Serwis może
              zawierać odnośniki do innych stron internetowych. Wydawca nie
              ponosi odpowiedzialności za funkcjonowanie tych stron oraz treści
              tam zawarte.
            </li>
          </ol>
        </li>
        <li>
          <h5>Prawa autorskie</h5>
          <ol role="list">
            <li>
              Zabronione jest kopiowanie i rozpowszechnianie serwisu w całości
              lub części bez uzyskania uprzedniej zgody. Nie dotyczy to
              tymczasowego zwielokrotniania zawartości serwisu w pamięci
              operacyjnej komputera. Prawa autorskie do serwisu lub jego
              poszczególnych elementów oraz prawa do znaków towarowych
              zastrzeżone są na rzecz Wydawcy serwisu lub innych podmiotów.
              Elementy, których Wydawca nie jest autorem zostały opublikowane na
              podstawie udzielonej licencji. Wydawca oświadcza, iż materiały
              graficzne oraz animacje promujące poszczególne materiały pochodzą
              od producentów lub podmiotów z nimi współpracujących. Naruszenie
              praw własności intelektualnej skutkuje odpowiedzialnością cywilną
              lub karną.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
